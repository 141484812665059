<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :popupComponent="popupComponent"
        title="Google Sheet Sync"
        icon="mdi-google-spreadsheet"
        stateendpoint="googleSheet.googleSheets"
        :show-add-button="false"
        :actions="actions"
        :show-default-action-buttons="false"
        :action-buttons="actionButton"
    />
  </div>
</template>
<script>
import ListPage from "../components/ListPage";
import GoogleSheetSyncPopup from "../components/popups/GoogleSheetSyncPopup";

export default {
  name: 'GoogleSheetSync',
  metaInfo: {
    title: 'shantoitfirm.com',
    titleTemplate: 'Admin Dashboard - Google Sheets Sync | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage, GoogleSheetSyncPopup},
  data() {
    return {
      columns: [
        {value: 'name'},
        {value: 'fieldOne'},
        {value: 'fieldTwo'},
        {value: 'action'},
      ],
      headers: [
        {
          text: 'Name',
          value: 'settingsName'
        },
        {
          text: 'Sheet',
          value: 'sheet'
        },
        {
          text: 'Ahrefs Column',
          value: 'updateColumn'
        },
        {
          text: 'Ahref Sheet Column',
          value: 'sheetColumn'
        },
        {
          text: 'MOZ Column',
          value: 'mozUpdateColumn'
        },
        {
          text: 'MOZ Sheet Column',
          value: 'mozSheetColumn'
        },
        {
          text: 'First Row',
          value: 'fieldOne',
          align: 'right'
        },
        {
          text: 'Last Row',
          value: 'fieldTwo',
          align: 'right'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      actions: {
        load: 'loadGoogleSheets',
        update: 'updateGoogleSheets',
        syncItem: 'syncGoogleSheet',
        syncItemMoz: 'syncMozGoogleSheet',
      },
      actionButton: [
        {
          category: 'edit',
          icon: 'mdi-pencil',
          color: '',
          text: 'Edit',
          clickHandler: 'editItem'
        },
        {
          category: 'syncItem',
          icon: 'mdi-chevron-triple-up',
          color: '',
          text: 'Ahref Sync Sheet',
          clickHandler: 'syncItem'
        },
        /*{
          category: 'syncItemMoz',
          icon: 'mdi-upload-multiple',
          color: '',
          text: 'Moz Sync Sheet',
          clickHandler: 'syncItemMoz'
        }*/
      ]
    }
  },
  computed: {
    popupComponent() {
      return GoogleSheetSyncPopup;
    }
  }
}
</script>